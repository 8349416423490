import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="uk-section" uk-height-viewport="true">
      <div className="uk-container uk-text-center uk-padding">
        <h1 className="uk-color-muted" style={{ fontSize: '4rem' }}>Oops!</h1>
        <p className="uk-color-muted">La p&aacute;gina que buscas no existe...</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
